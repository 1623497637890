import React, { Suspense } from 'react';

import './App.css';
//import Rinfuz from './components/rinfuz/Rinfuz';
import Loader from './loader.svg';
const Navbar = React.lazy(() => import('./components/navbar/NavBar'));
const Slider = React.lazy(() => import('./components/slider/Slider'));
const About = React.lazy(() => import('./components/about/About'));
const Globus = React.lazy(() => import('./components/globus/Globus'));
const Location = React.lazy(() => import('./components/loaction/Location'));
const Footer = React.lazy(() => import('./components/footer/Footer'));
const Articles = React.lazy(() => import('./components/articles/Articles'));
const NewsAboutUs = React.lazy(() => import('./components/news/NewsAboutUs'));
const Rinfuz = React.lazy(() => import('./components/rinfuz/Rinfuz'));

function App() {
  return (
    <div className="App" id="top">
      <Suspense
        fallback={
          <div className="loader">
            <img src={Loader} alt="Loader" />
          </div>
        }
      >
        <Navbar />
        <Slider />
        <About />
        <Globus />
        <Rinfuz />
        <Articles />
        <NewsAboutUs />
        <Location />
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;
